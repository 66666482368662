<template>
	<div class="page favorite-apartments">

			<apartments-compere></apartments-compere>
			<div class="apartment-blocks scrollable-y">
                <transition-group appear>
                    <template v-for="(aptShow, aptId) in products" :key="aptId">
                        <div v-if="products && products[aptId] && aptShow">
                            <ApartmentBlock :apartment="products[aptId]"/>
                        </div>  
                    </template>
                </transition-group>
            </div>
	</div>
</template>

<script>
import ApartmentsCompere from "@/components/ApartmentsCompere";
import {mapGetters} from "vuex";
import ApartmentBlock from "@/components/ApartmentBlock";

export default {
		name: "FavoriteApartments",
		components: {
			ApartmentsCompere,
			ApartmentBlock
		},
		computed: {
				...mapGetters(['apartmentsCompare','products', 'compareEnabled','apartmentFavs','apartmentPageEnabled', 'currentProjectId', 'apartmentsFilter'])
		},
		watch: {
				localAptComp: {
						handler(newVal){
								this.$store.commit('apartmentsCompare', newVal)
						},
						deep: true
				},
				apartmentsCompare(newVal){
						this.localAptComp = newVal
				}
		},
		data(){
				return {
						localAptComp: {}
				}
		},
		methods: {
				navigateToApartment(apt){
						if(this.apartmentPageEnabled && apt.type_show_page && !apt.hide_page_by_status){
								this.$router.push({name: 'apartment', params: {projectId: this.currentProjectId, apartmentId: apt.id}})
						}
				}
		},
		mounted() {
				this.localAptComp = this.apartmentsCompare
		},
		beforeRouteEnter(to, from, next){
				next(async (vm) => {
						await vm.$store.dispatch('searchProducts')
				})
		},
}
</script>

<style scoped lang="scss">
.favorite-apartments {
		display: flex;
		flex-flow: column;
		.apartment-blocks {

			padding: 4rem 6rem;
			max-height: 60rem;
	
			&.v-enter-active,
			&.v-leave-active {
				transition: transform 1s ease;
			}
	
			&.v-enter-from,
			&.v-leave-to {
				transform: translateX(100vw);
			}
		}
		:deep(.apartments-compere){
				position: relative;
				left: auto;
				bottom: auto;
		}

		.favs-modal {
				margin: auto auto 40px;
				background-color: rgb(0 0 0 / 80%);
				width: 100%;
				max-width: 720px;
				padding: 15px;

				.favs-header {
						display: flex;
						margin-bottom: 40px;
				}

				.favs-title {flex-grow: 1;}
		}

		.favs-table {
				max-width: 100%;
				overflow-x: auto;

				table {
						width: 100%;
						text-align: center;

						thead {
								border-bottom: solid 1px;
						}

						thead tr th {
								padding: 0 5px 10px;
						}

						thead tr:first-child th:first-child {
								text-align: start;
						}

						tbody tr td {
								padding: 5px 5px;
						}

						tbody tr:first-child td {
								padding-top: 10px;
						}
				}
		}

		:deep(.fav-button-wrapper) a {
				display: none;
		}
}

.fp-side-menu-wrapper + .fp-app-wrapper {
		.favs-modal {
				background-color: transparent;
				background-image: linear-gradient(to left, rgb(0 0 0 / 80%) 50%,rgb(0 0 0 / 90%) 100%);
				backdrop-filter: blur(5px);
		}

		.favorite-apartments {
				padding-bottom: 50px;
		}
}
.is-mobile{
	.favorite-apartments {
		display: flex;
		flex-flow: column;
		.apartment-blocks {

			padding: 0;
			max-height: 70%;

		}
	}
}
</style>
