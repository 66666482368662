<template>
    <div class="area-page-tab">
        <!-- <close-btn @click.stop="close"></close-btn> -->
        <div class="iconSwitcher">
            <FpNewIcon iconName="cube3d" class="circledIcon cursor-pointer"
                :class="{ bgPurple: currentMapType === '2d' }" size="1.3rem" @click="currentMapType = '2d'" />
            <FpNewIcon iconName="rectangle" class="circledIcon cursor-pointer"
                :class="{ bgPurple: currentMapType === '3d' }" size="1.3rem" @click="currentMapType = '3d'" />
        </div>
        <div class="area-content">
            <div v-if="contentPage && !globalLoading" class="mapWrapper" :class="'map'+currentMapType">
                <google-map
                    ref="googleMap"
                    :options="mapOptions"
                    :landmark-groups="landmarkGroups"
                    :center="center"
                    :zoom="zoom">
                </google-map>
                <MapBoxComponent class="mapbox" ref="mapBox"
                    :modelUrl="modelUrl"
                    :isMobile="isMobile"
                    :center="center"
                    :projectDefaultView="currentProject.default_view"/>

            </div>
            <div v-if="loading">
                <loading-data></loading-data>
            </div>
        </div>
        <div class="area-buttons" v-if="contentPage && currentMapType === '2d'">
          <nav class="nav">
            <ul class="list-menu">
              <template v-for="(poi, poiIndex) in customStyle" :key="poiIndex">
                <li v-if="poi.showInMenu">
                  <a class="list-menu-item"
                     :class="[`poi-${poiIndex}`, this.activePOI && this.activePOI[poiIndex] ? 'visible' : 'invisible']"
                     href="javascript:void(0)" @click="filter(poiIndex)">
                    <div v-if="isMobile"> {{ $t(poi.label) }}</div>
                    <FpNewIcon v-if="poi.icon && !isMobile" :iconName="poi.icon" class="circledIcon cursor-pointer" size="1rem" />
                    <span v-if="!isMobile">{{ $t(poi.label) }}</span>
                  </a>
                </li>
              </template>
            </ul>
          </nav>
        </div>
    </div>
</template>

<script>
import GoogleMap from "@/components/GoogleMap"
import { mapGetters } from "vuex"
import LoadingData from "@/components/LoadingData"
import MapBoxComponent from "@/components/MapBoxComponent.vue";
import FpNewIcon from '@/components/buttons/FpNewIcon.vue';

const mapStyleSilver = [
    {
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#f5f5f5"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#bdbdbd"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#eeeeee"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e5e5e5"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#98c89b"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#fafffb"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dadada"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e5e5e5"
            }
        ]
    },
    {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#eeeeee"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#c9c9c9"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#82d8e3"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#82d7e3"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    }
];

const mapPoi = {
    all: {
        label: 'הכל',
        visibility: true,
        showInMenu: true,
        icon: 'show',
        mapStyle: {
            featureType: "poi",
            stylers: [
                {
                    visibility: "on"
                }
            ]
        }
    },
    attraction: {
        label: 'אטרקציות',
        visibility: true,
        showInMenu: true,
        icon: 'smiley',
        mapStyle: {
            featureType: "poi.attraction",
            stylers: [
                {
                    visibility: "on"
                }
            ]
        }
    },
    business: {
        label: 'עסקים',
        visibility: true,
        showInMenu: true,
        icon: 'briefCase',
        mapStyle: {
            featureType: "poi.business",
            stylers: [
                {
                    visibility: "on"
                }
            ]
        }
    },
    business_shopping: {
        label: 'חנויות',
        visibility: true,
        showInMenu: true,
        icon: 'briefCase2',
        mapStyle: {
            featureType: "poi.business.shopping",
            stylers: [
                {
                    visibility: "on"
                }
            ]
        }
    },
    business_food_and_drink: {
        label: 'מסעדות',
        visibility: true,
        showInMenu: true,
        icon: 'spoonAndFork',
        mapStyle: {
            featureType: "poi.business.food_and_drink",
            stylers: [
                {
                    visibility: "on"
                }
            ]
        }
    },
    business_gas_station: {
        label: 'תחנות דלק',
        visibility: true,
        showInMenu: true,
        icon: 'gasStation',
        mapStyle: {
            featureType: "poi.business.gas_station",
            stylers: [
                {
                    visibility: "on"
                }
            ]
        }
    },
    business_car_rental: {
        label: 'השכרת רכב',
        visibility: true,
        showInMenu: true,
        mapStyle: {
            featureType: "poi.business.car_rental",
            stylers: [
                {
                    visibility: "on"
                }
            ]
        }
    },
    business_lodging: {
        label: 'מלונות וצימרים',
        visibility: true,
        showInMenu: true,
        icon: 'buildings-3d',
        mapStyle: {
            featureType: "poi.business.lodging",
            stylers: [
                {
                    visibility: "on"
                }
            ]
        }
    },
    government: {
        label: 'ממשלה',
        visibility: true,
        showInMenu: true,
        icon: 'building',
        mapStyle: {
            featureType: "poi.government",
            stylers: [
                {
                    visibility: "on"
                }
            ]
        }
    },
    medical: {
        label: 'רפואה',
        visibility: true,
        showInMenu: true,
        icon: 'davidStar',
        mapStyle: {
            featureType: "poi.medical",
            stylers: [
                {
                    visibility: "on"
                }
            ]
        }
    },
    park: {
        label: 'פארקים',
        visibility: true,
        showInMenu: true,
        icon: 'tree',
        mapStyle: {
            featureType: "poi.park",
            stylers: [
                {
                    visibility: "on"
                }
            ]
        }
    },
    place_of_worship: {
        label: 'בתי כנסת',
        visibility: true,
        showInMenu: true,
        icon: 'church',
        mapStyle: {
            featureType: "poi.place_of_worship",
            stylers: [
                {
                    visibility: "on"
                }
            ]
        }
    },
    school: {
        label: 'בתי ספר',
        visibility: true,
        showInMenu: true,
        icon: 'church',
        mapStyle: {
            featureType: "poi.school",
            stylers: [
                {
                    visibility: "on"
                }
            ]
        }
    },
    sports_complex: {
        label: 'אצטדיונים',
        visibility: true,
        showInMenu: true,
        mapStyle: {
            featureType: "poi.sports_complex",
            stylers: [
                {
                    visibility: "on"
                }
            ]
        }
    },
    transit: {
        label: 'תחבורה',
        visibility: true,
        showInMenu: true,
        icon: 'bus',
        mapStyle: {
            featureType: "transit",
            stylers: [
                {
                    visibility: "on"
                }
            ]
        }
    },
    highway: {
        label: 'כבישים מהירים',
        visibility: true,
        showInMenu: true,
        mapStyle: {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#fa0000"
                }
            ]
        }
    }
}

export default {
    name: "AreaPageTab",
    components: {
        // MenuIcon, 
        // FpIcon, 
        LoadingData,
        GoogleMap,
        // CloseBtn,
        MapBoxComponent,
        FpNewIcon
    },
    props: {
        contentPage: {
            type: Object,
            default: null
        }
    },
    computed: {
        center() {
            if (this.contentPage && this.contentPage.map) {
                return {
                    lat: parseFloat(this.contentPage.map.center_coordinates_lat),
                    lng: parseFloat(this.contentPage.map.center_coordinates_lng),
                }
            }
            return null
        },
        zoom() {
            if (this.contentPage && this.contentPage.map) {
                if (this.isMobile && this.contentPage.map.zoom_mobile) {
                    return this.contentPage.map.zoom_mobile
                } else if (this.contentPage.map.zoom) {
                    return this.contentPage.map.zoom
                }
            }
            return null
        },
        landmarkGroups() {
            if (this.contentPage && this.contentPage.map) {
                const landmark_groups = [];
                const map = this.contentPage.map
                if (map.center_coordinates_icon && map.show_center_landmark && map.center_coordinates) {
                    landmark_groups.unshift({
                        label: map.center_label || 'מרכז',
                        icon: map.center_coordinates_icon,
                        landmarks: [
                            {
                                address: map.center_address,
                                coordinates: map.center_coordinates
                            }
                        ]
                    })
                }
                if (map.landmark_groups) {
                    map.landmark_groups.forEach(({ landmarks, label, icon, animated_button_icon, button_icon }) => {
                        landmark_groups.push({ landmarks, label, icon, animated_button_icon, button_icon })
                    })
                }
                return landmark_groups.map((lmg, lmgi) => {
                    lmg.visible = true
                    this.activeLMG[lmgi] = true
                    return lmg
                })
            }
            return null
        },
        mapOptions() {
            const styles = JSON.parse(JSON.stringify(mapStyleSilver))
            if (this.customStyle) {
                Object.entries(this.customStyle).forEach((poi) => {
                    //const key = poi[0]
                    const value = poi[1]
                    styles.push(value.mapStyle)
                });
            }
            return {
                styles
            }
        },
        getAreasMenu(){
          const menuItems = [];
          menuItems.push(this.contentPage);
          if(this.contentPage && this.contentPage.parent){
            menuItems.push(this.contentPage.parent);
          }
          if(this.contentPage && this.contentPage.children && this.contentPage.children.length){
            this.contentPage.children.forEach(child => {
              menuItems.push(child);
            });
          }
          if(this.contentPage && this.contentPage.siblings && this.contentPage.siblings.length){
            this.contentPage.siblings.forEach(sibling => {
              menuItems.push(sibling);
            });
          }
          // sort by ID
          menuItems.sort((a, b) => a.ID - b.ID);
          return menuItems;
        },
        ...mapGetters(['isMobile', 'modelUrl', 'currentProject','currentProjectId', 'globalLoading'])
    },
    watch: {
        contentPage(newVal) {
            this.updateMapFromPostSettings(newVal)
          console.log({getAreasMenu: this.getAreasMenu})
        }
    },
    data() {
        return {
            loading: false,
            currentLandmark: null,
            customStyle: null,
            allPoi: 'on',
            activeLMG: {},
            activePOI: {},
            currentMapType: '2d'
        }
    },
    methods: {
        updateMapFromPostSettings(post) {
            if (post && post.map && post.map.built_in_poi) {
                const builtInPoi = post.map.built_in_poi
                Object.entries(this.customStyle).forEach((poi) => {
                    const key = poi[0]
                    //const value = poi[1]
                    if (key && this.customStyle[key]) {
                        this.customStyle[key].showInMenu = builtInPoi.findIndex(k => k === key) !== -1
                        /*
                        if(!this.customStyle[key].showInMenu){
                        }*/
                        this.customStyle[key].visibility = false

                        if (key === "highway") {
                            this.customStyle[key].mapStyle.stylers = this.customStyle[key].mapStyle.stylers.map((style) => {
                                style.color = "#4E4E4E"
                                return style
                            })
                        } else {
                            this.customStyle[key].mapStyle.stylers = this.customStyle[key].mapStyle.stylers.map((style) => {
                                style.visibility = "off"
                                return style
                            })
                        }

                    }
                });
            }
        },
        close() {
            this.$router.push({ name: 'home' })
        },
        selectLandmarkGroup(lmgi, landmarkGroup) {
            this.activeLMG[lmgi] = landmarkGroup.visible = !landmarkGroup.visible
            this.$refs.googleMap.toggleLandmarkGroup(landmarkGroup)
        },
        isCurrentLandmark(landmark) {
            return this.currentLandmark === landmark
        },
        filter(poiKey) {
            const styles = JSON.parse(JSON.stringify(mapStyleSilver))
            const cs = this.customStyle
            if (cs[poiKey] && cs[poiKey].mapStyle && cs[poiKey].mapStyle.stylers) {
                if (poiKey === 'highway') {
                    cs[poiKey].mapStyle.stylers = cs[poiKey].mapStyle.stylers.map((style) => {
                        if (cs[poiKey].visibility) {
                            style.color = "#4E4E4E"
                            cs[poiKey].visibility = false
                        } else {
                            style.color = "#fa0000"
                            cs[poiKey].visibility = true
                        }
                        return style
                    })
                } else {
                    cs[poiKey].mapStyle.stylers = cs[poiKey].mapStyle.stylers.map((style) => {
                        if (cs[poiKey].visibility) {
                            style.visibility = "off"
                            this.activePOI[poiKey] = cs[poiKey].visibility = false
                        } else {
                            style.visibility = "on"
                            this.activePOI[poiKey] = cs[poiKey].visibility = true
                        }
                        return style
                    })
                }
            }

            Object.entries(cs).forEach((poi) => {
                const key = poi[0]
                const value = poi[1]
                if (poiKey === 'all') {
                    if (key === 'all') {
                        styles.push(value.mapStyle)
                    } else {
                        cs[key].visibility = cs.all.visibility
                        if (key === 'highway') {
                            cs[key].mapStyle.stylers = cs[key].mapStyle.stylers.map((style) => {
                                if (cs[key].visibility) {
                                    style.color = "#4E4E4E"
                                    cs[key].visibility = true
                                } else {
                                    style.color = "#fa0000"
                                    cs[key].visibility = false
                                }
                                return style
                            })
                        } else {
                            cs[key].mapStyle.stylers = JSON.parse(JSON.stringify(this.customStyle.all.mapStyle.stylers))
                        }
                    }
                } else {
                    if (key === 'all' && !value.showInMenu) {
                        value.visibility = false
                        value.mapStyle.stylers = value.mapStyle.stylers.map((style) => {
                            style.visibility = "off"
                            return style
                        })
                    }
                    styles.push(value.mapStyle)
                }
            })

            if (this.$refs.googleMap && this.$refs.googleMap.map) {
                this.$refs.googleMap.map.setOptions({
                    styles: JSON.parse(JSON.stringify(styles))
                })
            }
        }
    },
    mounted() {
        console.log({
          areaPageTabContentPage: this.contentPage
        })
        this.customStyle = mapPoi
        this.updateMapFromPostSettings(this.contentPage)
    }
}
</script>

<style scoped lang="scss">
.page.area {
    display: flex;
    align-items: center;
    justify-content: center;


}

.area-page-tab {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 87.46%, #000000 93.93%);

    //border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    flex-flow: column;
    overflow: hidden;

    &.v-enter-active,
    &.v-leave-active {
        transition: opacity .8s ease;
    }

    &.v-enter-from,
    &.v-leave-to {
        opacity: 0;
    }

    .close-btn {
        position: absolute;
        top: 10px;
        left: 5px;
    }

    .areas-tabs {
      min-height: 35px;
      z-index: 4;
      position: relative;
      background-color: #000;
    }

    .area-buttons {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 5;
    }

    .area-buttons,
    .areas-tabs {
        width: 100%;
        background-color: transparent;
        background: linear-gradient(0deg, #242424 0%, rgba(37, 37, 37, 0.95) 23.75%, rgba(37, 37, 37, 0.85) 48.33%, rgba(38, 38, 38, 0.50) 78.13%, rgba(39, 39, 39, 0.00) 100%);
        backdrop-filter: blur(5px);
        border: none;
        border-radius: 0;
        align-items: center;

        .circledIcon {
            width: 3rem;
            height: 3rem;
            border: 1px solid rgba(255, 255, 255, 0.90);

            &:hover {
                border: 1px solid var(--primary);
            }

            &:focus,
            &.active {
                background-color: var(--primary);
            }
        }

        .nav {
            overflow-x: auto;
            height: 100%;
        }

        .list-menu {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            li:first-child {
              margin-inline-start: auto;
            }

            li:last-child {
              margin-inline-end: auto;
            }

            :deep(.list-menu-item) {
                
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;
                gap: .5rem;
                width: 8rem;
                text-align: center;
                img {
                    width: 26px;
                    height: auto;
                }

                &.visible {
                    .circledIcon {
                        border: none;
                        background-color: var(--primary);
                    }
                }


            }
        }
    }
    .iconSwitcher{
        position: absolute;
        top: 1rem;
        right: 1rem;
        z-index: 4;
        background: #848484;
        border-radius: 6.25rem;
        .circledIcon {
            width: 2.5rem;
            height: 2.5rem;
        }
      
      }
    .area-sidebar {
        padding: 1.25rem 0;
        width: 215px;
        border-left: solid 1px #434646;
        margin: 1.4375rem 0;
        height: calc(100% - 1.4375rem * 2);
        display: flex;
        flex-flow: column;
    }

    .area-content {
        flex-grow: 1;

        .mapWrapper {
            height: 100%;
            width: 100%;
            .google-map {
                transition: .8s all ease;
                

            }
            .mapbox {
                
                transition: .8s all ease;
                
            }
            &.map3d{
                .google-map {
                    opacity: 0;
                    z-index:-1;     
    
                }
                .mapbox {
                    
                    opacity: 1;


                }
                
            }
            &.map2d{
                .google-map {
                    opacity: 1;
                    z-index: 3;     
    
                }
                .mapbox { 
                    opacity: 0; 
                    z-index: -1;                   

                }
                
            }

        }

        iframe {
            height: 100%;
            width: 100%;
        }
    }

    .area-title {
        text-align: center;
        padding-bottom: 1rem;
        border-bottom: solid 1px #475257;
        margin-bottom: 1.875rem;
        margin-left: 1.25rem;
        margin-right: 1.25rem;
    }

}

.is-mobile {
    .area-page-tab {
        display: flex;
        flex-flow: column;
        max-width: none;

        border-radius: 0;
        border: none;

        .area-title {
            margin-bottom: 0;
        }

        .area-content {
            padding: 0;
            display: flex;
            flex-flow: column;

            iframe {
                flex-grow: 1;
            }
        }

        .area-sidebar {
            width: auto;
            height: auto;
            overflow: auto;
            border-left: none;
            margin-bottom: 0;

            .nav {
                overflow-x: auto;
                overflow-y: hidden;

                /* scrollbar design for Chrome, Safari and Opera */
                &::-webkit-scrollbar {
                    height: 2px;
                    background-color: #aaa;
                }

                &::-webkit-scrollbar-thumb {
                    background: var(--primary);
                }

                /* scrollbar design for Edge and Firefox */
                & {
                    scrollbar-width: thin;
                    scrollbar-color: var(--primary) #aaa;
                }
            }

            .list-menu {
                display: flex;
                
                flex-flow: row nowrap;

                .list-menu-item {
                    white-space: nowrap;
                    padding-right: 1.25rem;
                    padding-left: 1.25rem;
                    border-left: solid 1px transparent;
                    border-right: solid 1px transparent;
                    border-top: none;
                    border-bottom: solid 3px transparent;
                    
                    &:hover,
                    &:focus {
                        border-right-color: var(--primary-darkerer);
                        border-left-color: var(--primary-darkerer);
                        border-bottom-color: var(--primary-light);
                    }
                }
            }
        }
        .area-buttons {
            position: absolute;
            top: 60px;
            bottom: auto;
            left: 0;           
            padding-top: .7rem;     
            padding-bottom: 4rem;     
            z-index: 4;
            background-color: transparent;
            background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0.75) 23.75%, rgba(0, 0, 0, 0.65) 48.33%, rgba(0, 0, 0, 0.00) 100%);
            backdrop-filter: none;
            border: none;
            border-radius: 0;
            
    
            .circledIcon {
                width: 3rem;
                height: 3rem;
                border: 1px solid rgba(255, 255, 255, 0.90);
    
                &:hover {
                    border: 1px solid var(--primary);
                }
    
                &:focus,
                &.active {
                    background-color: var(--primary);
                }
            }
    

            .nav {
                overflow-x: auto;
                overflow-y: hidden;
                height: 2.3rem;
                /* scrollbar design for Chrome, Safari and Opera */

            }
    
            .list-menu {
                display: flex;
                align-items: center;
                flex-flow: row nowrap;
                justify-content: initial;

                :deep(.list-menu-item) {
                    padding: .5rem 1rem;
                    margin: 0 .35rem;
                    white-space: nowrap; 
             
                    width: auto;
                    border: 1px solid #6B6C6D;
                    border-radius: 6.25rem;
                    text-align: center;
                    position: relative;
                    &::before{
                        content: '';
                        width: 5px;
                        height: 5px;
                        position: absolute;
                        right: -3px;
                        top: 50%;
                        background-color: #6B6C6D;
                        border-radius: 50%;
                      }
                    img {
                        width: 26px;
                        height: auto;
                    }
    
                    &.visible {
                        border-color: var(--primary);
                    }
    
    
                }
            }
    
        }
    }
    .iconSwitcher{
        position: absolute;
        top: 7rem;
        right: .7rem;
        z-index: 14;
        background: #848484;
        border-radius: 6.25rem;     
      }
}

.ltr {
    .area-page-tab{


    }
}
</style>