<template>
	<transition appear>
		<div class="apartments-table scrollable-y">
				
			<div v-for="(floor, floorI) in apartmentsBySeparateTitle"
					:key="floorI"
				>
				<div v-if="apartmentsBySeparateTitle.length > 1" class="floorHeader flex align-center">
					<span class="floorHeaderText">{{separateTitleName}} {{floor.title}}</span>
				</div>
				<table :class="{uadc: useApartmentFieldsOrder}">
					<thead>
							<template v-if="useApartmentFieldsOrder && apartmentTableFieldsOrder && apartmentTableFieldsOrder.length">
							<tr>
									<th
										v-for="(column, columnIndex) in apartmentTableFieldsOrder"
										:key="columnIndex"
										:title="column.title"
									>
										<a
											v-if="column.field === 'favorites'"
											href="javascript:"
											@click="navigateToFavorites"
										>{{$t('מועדפים')}}</a>
										<span v-else-if="column.field === 'compare'">{{$t('השוואה')}}</span>
										<span v-else>{{ column.title }}</span>
									</th>
									
							</tr>
							</template>
							<template v-else>
									<tr>
											<th :title="$t('דגם')"><fp-icon symbol="buildings-3d"></fp-icon></th>
											<th :title="$t('קומה')"><fp-icon symbol="stairs"></fp-icon></th>
											<th :title="$t('חדרים')"><fp-icon symbol="open-door"></fp-icon></th>
											<th :title="$t('מ&quot;ר דירה')"><fp-icon symbol="roller"></fp-icon></th>
											<th :title="$t('מ&quot;ר מרפסת')"><fp-icon symbol="porch"></fp-icon></th>
											<th v-if="showCompare" :title="$t('השוואה')"><fp-icon symbol="compare"></fp-icon></th>
									</tr>
							</template>
					</thead>
					<tbody v-if="loading">
					<tr>
						<td colspan="6">
								<loading-data></loading-data>
						</td>
					</tr>
					</tbody>
					<tbody v-else>
						<template v-if="useApartmentFieldsOrder && apartmentTableFieldsOrder && apartmentTableFieldsOrder.length">
								<tr
									v-for="(apt, aptI) in floor.apartments"
									:key="aptI"
									:class="{active: currentAptId === apt.id}"
									tabindex="0"
									
									@mouseenter="apartmentHover(apt)"
								>
	
							
										<td @click="goToApartmentPage(apt, aptI)"
												v-for="(column, aptColumnIndex) in apartmentTableFieldsOrder"
												:key="aptColumnIndex"
												:title="aptDetail(apt, column.field)" 
										>
												<template v-if="column.field === 'product_post_title'">
														<apartment-title :apartment="apt" :show-favs-button="false"></apartment-title>
												</template>
												<template v-else-if="column.field === 'product_acf_available'">
														<apartment-availability :available="apt.available"></apartment-availability>
												</template>
												<template v-else-if="column.field === 'favorites'">
														<apartment-fav-button :apt-id="apt.id" :show-link-to-favs="false"></apartment-fav-button>
												</template>
												<template v-else-if="column.field === 'compare'">
													<fp-circle-check-box v-if="compare_enabled" v-model="apartmentsCompare[apt.id]" @update.stop :size="12"
													:checked="true"></fp-circle-check-box>
												</template>
												<span v-else>{{ aptDetail(apt, column.field) }}</span>
										</td>
										<!-- <td v-if="showCompare" class="checkBox">
												
												<fp-circle-check-box v-if="compare_enabled" v-model="apartmentsCompare[apt.id]" @update.stop :size="12"
												:checked="true"></fp-circle-check-box>
										</td> -->
								</tr>
						</template>
						<template v-else>
								<tr
									v-for="(apt, aptI) in floor.apartments"
									:key="aptI"
									:class="{active: currentAptId === apt.id}"
									tabindex="0"
									
									@mouseover="apartmentHover(apt)"
								>
										<td @click="goToApartmentPage(apt, aptI)"><apartment-title :apartment="apt" :show-favs-button="false" ></apartment-title></td>
										<td @click="goToApartmentPage(apt, aptI)">
												<span v-if="apt.floor_name">{{apt.floor_name}}</span>
												<span v-else>{{apt.floor}}</span>
										</td>
										<td @click="goToApartmentPage(apt, aptI)">
												<span v-if="apt.type_rooms">{{apt.type_rooms}}</span>
										</td>
										<td @click="goToApartmentPage(apt, aptI)">
												<span v-if="apt.type_dimensions">
														<span v-if="apt.type_dimensions.apartment_sm">{{apt.type_dimensions.apartment_sm}}</span>
												</span>
										</td>
										<td @click="goToApartmentPage(apt, aptI)">
												<span v-if="apt.type_dimensions">
													<span v-if="apt.type_dimensions.porch_sm">{{apt.type_dimensions.porch_sm}}</span>
												</span>
										</td>
										<td v-if="showCompare">
												<fp-circle-check-box v-if="compare_enabled" v-model="apartmentsCompare[apt.id]" @update.stop :size="12" :checked="true"></fp-circle-check-box>
										</td>
								</tr>
						</template>
					</tbody>
				</table>
			</div>
			<!-- <div v-if="hasCompare" style="margin-top: 15px; text-align: center">
					<fp-button type="button" @click="doCompression" variant="primary-outline">{{$t('השוואת דירות')}}</fp-button>
			</div> -->
		</div>

	</transition>
	<!-- <div v-if="hasCompare" > -->
		<div v-if="!isMobile" class="compareButtonWrapper" :class="{show: hasCompare}"
			@mouseenter="animate = true"
			@mouseleave="animate = false"
			>
			<!-- <FpBeautifullButton :size="'8rem'" :text="$t('השוואת דירות')" @click="doCompression"/> -->
			<div class=""
				:style="{
					width: '120px',
					height: '120px',
					position: 'relative'
				}"
				@click="doCompression"
	
				>
				<AnimatedCircles :animate="animate" :color="colors.primary"/>
				<span >{{$t('השוואה')}}</span>

			</div>
		</div>
		<div v-else class="mobileVompareButton" :class="{show: hasCompare}" @click="doCompression"><span>{{$t('השוואה')}}</span></div>
	<!-- </div> -->
</template>

<script>
import {mapGetters} from "vuex"
// import FpCheckbox from "@/components/buttons/FpCheckbox"
import FpCircleCheckBox from "@/components/buttons/FpCircleCheckBox"
// import FpButton from "@/components/buttons/FpButton"
import AnimatedCircles from "@/components/AnimatedCircles.vue"
import FpIcon from "@/components/FpIcon"
import LoadingData from "@/components/LoadingData"
import ApartmentTitle from "@/components/apartment/ApartmentTitle.vue"
import ApartmentAvailability from "@/components/apartment/ApartmentAvailability.vue"
import ApartmentFavButton from "@/components/buttons/ApartmentFavButton.vue"
import numeral from "numeral"
// import FpBeautifullButton from "@/components/buttons/FpBeautifullButton";
export default {
		name: "ApartmentsTable",
		components: {
			ApartmentFavButton, 
			ApartmentAvailability,
			ApartmentTitle, 
			LoadingData, 
			FpIcon, 
			AnimatedCircles, 
			FpCircleCheckBox,
			// FpBeautifullButton
			},
		props: {
				apartments: {
						type: [Array, Object],
						default: null
				},
				currentApt: {
						default: null
				},
				loading: {
						default: false,
						type: Boolean
				},
				showCompare: {
						default: true,
						type: Boolean
				}
		},
    emits: ['apartment-clicked'],
		computed: {
        apartmentsSortingDirection(){
          if(this.$store.getters.settings && this.$store.getters.settings.apartments_data_view && this.$store.getters.settings.apartments_data_view.direction){
            return this.$store.getters.settings.apartments_data_view.direction
          }
          return 'ASC'
        },
				apartment_name_format(){
						if(this.filterOptions && this.filterOptions.apartment_name_format){
								return this.$store.getters.settings.apartments_filter.options.apartment_name_format
						}
						return null
				},
				apartmentsByFloors(){
					const uniqueFloors = [...new Set(this.apartments.map(item => item.floor_name))]
          return uniqueFloors.map(floorName => {
            return {
              floorName,
              apartments: this.apartments.filter(app => app.floor_name === floorName)
            }
          })
				},
        separateTitleName(){
          if(this.separate_titles){
            if(this.separate_titles === 'floor'){
              if(this.locale === 'es'){
                return 'Piso'
              }
              if(this.locale === 'en'){
                return 'Floor'
              }
              return 'קומה'
            }else if(this.separate_titles === 'building'){
              if(this.locale === 'es'){
                return 'Edificio'
              }
              if(this.locale === 'en'){
                return 'Building'
              }
              return 'בניין'
            }else if(this.separate_titles === 'quarter'){
              if(this.locale === 'es'){
                return 'Barrio'
              }
              if(this.locale === 'en'){
                return 'Quarter'
              }
              return 'שכונה'
            }else if(this.separate_titles === 'plot'){
              if(this.locale === 'es'){
                return 'Parcela'
              }
              if (this.locale === 'en') {
                return 'Plot'
              }
              return 'שטח'
            }
          }
          if(this.locale === 'es'){
            return 'Piso'
          }
          if(this.locale === 'en'){
            return 'Floor'
          }
          return 'קומה'
        },
        apartmentsBySeparateTitle(){
          if(this.separate_titles){
            const uniqueTitles = [...new Set(this.apartments.map(item => {
              if(this.separate_titles === 'floor'){
                return item.floor_name
              }else if(this.separate_titles === 'building'){
                return item.type_building?.name || 'לא מוגדר בניין'
              }else if(this.separate_titles === 'quarter'){
                return item.type_quarter?.name || 'לא מוגדר שכונה'
              }else if(this.separate_titles === 'plot'){
                return item.type_plot?.name || 'לא מוגדר מגרש'
              }
              return item.floor_name || item.floor
            }))]
            return uniqueTitles.map(title => {
              return {
                title,
                apartments: this.apartments.filter(app => {
                  if(this.separate_titles === 'floor'){
                    return app.floor_name === title
                  }else if(this.separate_titles === 'building'){
                    return app.type_building?.name === title
                  }else if(this.separate_titles === 'quarter'){
                    return app.type_quarter?.name === title
                  }else if(this.separate_titles === 'plot'){
                    return app.type_plot?.name === title
                  }
                  return app.floor_name === title || app.floor === title
                })
              }
            }).sort((a, b) => {
              const getFloorNumber = (title) => {
                try{
                  if(title.includes('-')){
                    return parseInt(title.split('-')[0])
                  }
                  return parseInt(title)
                }catch{
                  return title
                }
              };

              if (this.separate_titles === 'floor') {
                const aFloor = getFloorNumber(a.title);
                const bFloor = getFloorNumber(b.title);
                if(this.apartmentsSortingDirection === 'DESC'){
                  return bFloor - aFloor;
                }
                return aFloor - bFloor;
              }
              if(this.apartmentsSortingDirection === 'DESC'){
                return b.title.localeCompare(a.title);
              }
              return a.title.localeCompare(b.title);
            })
          }
          return null
        },
        separate_titles(){
          if(this.filterOptions && this.filterOptions.separate_titles){
            return this.$store.getters.settings.apartments_filter.options.separate_titles
          }
          return null
        },
				compare_enabled(){
						if(this.filterOptions && this.filterOptions.compare_enabled){
								return this.$store.getters.settings.apartments_filter.options.compare_enabled
						}
						return null
				},
				hasCompare(){
						if(this.compare_enabled){
								if(this.apartmentsCompare){
										const keys = Object.keys(this.apartmentsCompare).filter(a => this.apartmentsCompare[a])
										return keys && keys.length > 1
								}
						}
						return null
				},
				titlesBySlug(){
						if(this.importTableHeaders){
								const headers = {};
								Object.keys(this.importTableHeaders).forEach(ithKey => {
										const ith = this.importTableHeaders[ithKey]
										headers[ith.slug] = ith.title
								})
								return headers
						}
						return []
				},
				isApartmentSelected(app){
					console.log("app", app)
					return true
				},
        useApartmentFieldsOrder(){
          return this.apartmentTableFieldsOrder != null;
        },
				...mapGetters(['isMobile','apartmentTableFieldsOrder', 'apartmentPageEnabled', 'colors', 'filterOptions', 'currentProjectId','locale'])
		},
		watch: {
				currentApt(newVal){
						if(newVal && newVal.id){
								this.currentAptId = newVal.id
						}else{
								this.currentAptId = null
						}
				},
				apartmentsCompare: {
						handler(newVal){
								this.$store.commit('apartmentsCompare', newVal)
						},
						deep: true
				}
		},
		data(){
				return {
						currentAptId: null,
						apartmentsCompare: {},
						animate: false
				}
		},
		methods: {
				navigateToFavorites(){
						this.$router.push(`/projects/${this.currentProjectId}/favorites`)
				},
				aptDetail(apt, field){
						if(apt && apt.display_fields && apt.display_fields.length){
								const df = apt.display_fields.find(f => f.field === field);
								if(df){
										return df.value;
								}
						}
						return ''
				},
				formatNumeric(value, slug){
						if(slug.indexOf('price') !== -1 || slug.indexOf('cost') !== -1 || slug.indexOf('fee') !== -1 || slug.indexOf('tax') !== -1){
								const num = numeral(value).format('0,0')
								return `₪${num}`
						}
						return value;
				},
				aptMoreDetailsBySlug(apt){
						if(apt && apt.more_details){
								const md = {}
								apt.more_details.forEach(mdItem => {
										if(mdItem.column_name && mdItem.value){
												md[mdItem.column_name] = mdItem.value
										}
								})
								return md
						}
						return null
				},
				aptTitle(apt){
						let titleTemplate = this.apartment_name_format
						if(!titleTemplate){
								return null
						}
						const variables = [
								"דירה",
								"טיפוס",
								"בניין",
								"חדרים",
								"קומה",
								"שכונה",
								"מגרש",
						]
						variables.forEach((variable) => {
								let text = null;
								switch (variable){
										case "דירה":
												text = apt.title
												break
										case "טיפוס":
												text = apt.type
												break
										case "בניין":
												text = apt.type_building ? apt.type_building.name : null
												break
										case "חדרים":
												text = apt.type_rooms
												break
										case "קומה":
												text = apt.floor
												break
										case "שכונה":
												text = apt.type_quarter ? apt.type_quarter.name : null
												break
										case "מגרש":
												text = apt.type_plot ? apt.type_plot.name : null
												break
								}

								if(text !== null){
										text = `${text}`
										const rex = new RegExp(`\\[${variable}]`, 'g')
										titleTemplate = titleTemplate.replace(rex,   `${text}`)
								}
						})
						return titleTemplate
				},
				goToApartmentPage(apt, index){
            console.log({
              action: 'goToApartmentPage',
              apartment: apt,
              aptIsClickable: this.aptIsClickable(apt)
            })
						if(this.aptIsClickable(apt)){
								this.$emit('apartment-clicked', apt, index)
						}
				},
				apartmentHover(apt){
						this.$frameBus.emit('message', {
								action: 'hover-apartment',
								apartment: apt
						})
				},
				aptIsClickable(apt){
						return this.apartmentPageEnabled && apt.type_show_page && !apt.hide_page_by_status
				},
				async doCompression(){
						if(this.compare_enabled){
								await this.$router.push({name: 'apartments-compare', params: {projectId: this.currentProjectId}})
						}
				},
		},
		mounted() {
			this.apartmentsCompare = JSON.parse(JSON.stringify(this.$store.getters.apartmentsCompare))
		}
}
</script>

<style scoped lang="scss">
.apartments-table {
		backdrop-filter: blur(5px);
		background: rgba(36, 36, 36, 1);
		.floorHeader {
			height: 3.32181rem;
			flex-shrink: 0;
			border-radius: 0.3125rem 0.3125rem 0 0;
			background: var(--grey-grey-2, #474747);
      padding-inline-start: 1rem;
		}
		.floorHeaderText{
			color: #FFF;
			text-align: right;
			font-size: 1.2rem;

			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
		table {
				width: 100%;
				position: relative;

				tr {
						cursor: pointer;
				}

				td, th {
						padding: 8px 5px;
						text-align: center;
				}

				th:first-child, td:first-child {
						text-align: start;
				}

				thead {

						background: #737373;
						color: #FFF;			
						font-style: normal;
							
					tr {
						height: 2.16106rem;
						font-size: 1rem;
						font-weight: 500;
					}
				}

				tbody {
					font-size: 1rem;
					font-style: normal;

				}
				tbody tr:hover,
				tbody tr.active,
				tbody tr:focus {
						//background-color: rgba(120, 120, 120, 0.51);
		
						background-color: color-mix(in srgb, var(--primary), transparent 30%);
				}
				tr:hover td,
				tr.active td,
				tr:focus td {
						//background-color: rgba(120, 120, 120, 0.51);
						opacity: 1;
		
				}

				&.uadc {
						th {
								//font-size: .8rem;
						}

						th,
						td:not(:first-child) {
								max-width: 88px;
						}

						thead {
						
								backdrop-filter: blur(5px);
						}
				}
		}
		.checkBox {
			padding-top: .5rem;
		}
		
	}
.compareButtonWrapper {
	transition: opacity .2s ease;
	position: absolute;
	left: -8.5rem;
	top: 40%;
	z-index: 23;
	background-color: rgba(0, 0, 0, 0.55);
	border-radius: 50%;
	cursor: pointer;
	opacity: 0;
	pointer-events: none;
	&.show {
		opacity: 1;
		pointer-events: all;
		
	}
}
.compareButtonWrapper span{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	
}
.mobileVompareButton {
	position: absolute;
	top: .6rem;
	right: 9rem;
	z-index: 12;
	padding: .5rem 1rem;
	border: 1px solid var(--primary);
	border-radius: 6.25rem;
	transition: opacity .2s ease;
	opacity: 0;
	pointer-events: none;
	&.show {
		opacity: 1;
		pointer-events: all;
		
	}
}

.ltr {
	.compareButtonWrapper {
		right: -8.5rem;
		left: auto;
		font-size: .8rem;
	}
}
.lowResLaptop {
	.apartments-table {
				
		tr {
			height: 2.16106rem;
			font-size: .8rem;
			font-weight: 500;
		}
	
		tbody {
			font-size: .8rem !important;
		}
	}
}
</style>
